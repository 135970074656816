// Home
.particles {
  height: 100%;
  left: 0px;
  position: absolute; 
  top: 0px;
  width: 100%;
}

.align-home {
  align-self: flex-start;
}

.home-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 3rem 1.4375rem 0;
}

.intro {
  color: white;

  p:last-of-type {
    margin-bottom: 0;
  }
}

.info {
  margin-bottom: 1.25rem;

  h1 {
    color: $yellow;
    font-size: 2rem;
    line-height: 1;
    margin: 0;
  }

  h2 {
    color: $white;
    font-family: $font-primary;
    font-size: 1.75rem;
    line-height: 1;
    margin: 0 auto;
  }
}

.home .align {
  z-index: 2;
}

.avatar {
  margin-top: 1.4375rem;
  max-height: calc(50vh - 6rem);
  max-width: calc(100vw - 2.875rem);
  z-index: 2;
}