// Footer
.footer {
  min-height: auto;
  padding: 4.5rem 0;
}

.list-inline {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: flex;

  &:not(:last-child) {
    margin-right: .75rem;
  }

  svg {
    height: 2rem;
    margin: .875rem .5rem 1rem 0;
    vertical-align: middle;
  }
}

.fa-layers,
.fa-github,
.fa-linkedin,
.fa-stack-overflow {
  font-size: 2rem;
  vertical-align: middle;
}

.fa-layers,
.fa-github,
.fa-linkedin {
  margin: 1rem .25rem 1rem 0;
}

.fa-stack-overflow {
  margin: 1rem .5rem 1rem 0;
}

.footer a {
  color: $white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.footer p {
  color: $grey5;
  margin: 0
}

.back-to-top {
  background-color: $white;
  border: .125rem solid rgba($black, .875);
  border-radius: 50%;
  cursor: pointer;
  font-size: 3rem;
  left: calc(50% - 1.5rem);
  line-height: 2rem;
  position: absolute;
  top: -1.6875rem;

  path {
    color: rgba($black, .875);
  }

  path,
  .rect {
    transition: all .3s cubic-bezier(.25, .8, .25, 1);
  }

  &:hover {
    path {
      fill: $green;
    }

    .rect {
      fill: rgba($black, .875);
    }
  }
}