// History
.more-content span {
  display: none;
}

.more-content .grid-item {
  display: flex;
  justify-content: center;
  width: 100%;

  &:hover a {
    overflow: hidden;
  }
}

span.note-isotopes {
  display: block;
  margin: 1.6875rem 0 0;
  overflow: hidden;
}

.more-link {
  display: block;
  clear: both;
}

.item_left {
  clear: left;
  float: left;
  position: relative;
}

.item_right {
  clear: right;
  float: right;
  position: relative;
}

.timeline {
  background: none;
  height: calc(100% - 1.5rem);
  margin: 0 auto;
  position: relative;

  .title {
    background: none repeat scroll 0 0 $white;
    border: .1875rem solid $grey2;
    border-radius: .375rem;
    clear: both;
    color: $grey;
    font-size: 1.125rem;
    font-weight: 400;
    height: 4.0625rem;
    margin: 1.9375rem auto;
    padding: .9375rem 0;
    text-align: center;
    text-transform: uppercase;
    width: 13.125rem;
  }

  .note {
    background: $white;
    border-radius: .75rem;
    box-shadow: 0 .1875rem .375rem rgba($black, .16), 0 .1875rem .375rem rgba($black, .23);
    list-style-type: none;
    margin: 0 0 1.5rem;
    padding: 2rem 1.5rem;
    position: relative;
    transition: all .5s ease 0s;
    width: 100%;
    z-index: 550;

    h4 {
      color: $grey5;
      font-family: $font-secondary;
      font-size: 1.5rem;
      line-height: 1;
      text-transform: uppercase;
    }

    h5 {
      font-size: 1rem;
      font-weight: 300;
    }

    p {
      font-size: .875rem;
      margin: .5rem 0;
    }

    .date {
      font-size: .875rem;
      text-transform: uppercase;
    }

    .arrow {
      color: $white;
      display: none;
      font-family: fontAwesome;
      font-size: .75rem;
      left: auto;
      position: absolute;
      right: -1.6%;
      top: 2rem;
      transform: rotate(360deg);
      transition: all .5s ease 0s;
    }

    &:after {
      background: none repeat scroll 0 0 $grey5;
      border: .125rem solid $grey;
      border-radius: 50%;
      content: "";
      display: none;
      height: 1.125rem;
      position: absolute;
      right: calc(-2.5vw - .5rem);
      top: 1.875rem;
      width: 1.125rem;
      z-index: 20;
    }

    &:nth-of-type(2n):after {
      left: calc(-2.5vw - .5rem);
      right: auto;
    }

    &:nth-of-type(2n) .arrow {
      left: -1.6%;
      right: auto;
      transform: rotate(180deg);
    }
    
    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      background: none repeat scroll 0 0 $white;
      box-shadow: 0 1.1875rem 2.375rem rgba($black, .30), 0 .9375rem .75rem rgba($black, .22);

      &:after {
        background: none repeat scroll 0 0 $lightpink;
        border: .125rem solid $pink;
      }

      .arrow {
        color: $white;
      }

      h4 {
        color: $pink;
      }
    }
  }

  .title + .note {
    margin-top: -1.25rem;
  }
}