// Skills
.jc-center {
  justify-content: center;
}

.chart {
  background: url(../images/gradient-border.svg) no-repeat 0 -.125rem;
  border-radius: 50%;
  box-shadow: inset 0 .0625rem 0 rgba($black, .5);
  display: inline-block;
  height: 11.875rem;
  margin-bottom: 1rem;
  position: relative;
  text-align: center;
  width: 11.875rem;

  &:after {
    border-radius: 50%;
    content: "";
    height: 10.625rem;
    left: .625rem;
    position: absolute;
    top: .625rem;
    width: 10.625rem;
  }

  &:before {
    background-color: $white;
    border-radius: 50%;
    box-shadow: 0 0 .75rem 0 rgba($black, .75), inset 0 .0625rem .625rem 0 rgba($black, .75);
    content: "";
    display: block;
    height: 8.125rem;
    left: 1.875rem;
    opacity: .125;
    position: absolute;
    top: 1.875rem;
    width: 8.125rem;
    z-index: 0;
  }

  canvas {
    border-radius: 50%;
    position: relative;
    top: .625rem;
  }
}

.percent {
  color: $white;
  font-family: $font-secondary;
  font-size: 4rem;
  left: -1.25rem;
  position: absolute;
  right: 0;
  text-shadow: 0 .1875rem .375rem rgba($black, .32), 0 .1875rem .375rem rgba($black, .46);
  top: 25%;
}

.percentage {
  color: $white;
  font-size: 2rem;
  position: absolute;
  right: 2.5rem;
  text-shadow: 0 .1875rem .375rem rgba($black, .32), 0 .1875rem .375rem rgba($black, .46);
  top: 30%;
}

.skills {
  padding-bottom: 3rem;

  h4 {
    color: $white;
    font-family: $font-secondary;
    margin-bottom: 2rem;

    span {
      color: $white1;
      font-family: $font-primary;
    }
  }

  // .skills-detail:nth-of-type(1) .percent,
  // .skills-detail:nth-of-type(1) .percentage {
  //   color: $purple;
  // }

  // .skills-detail:nth-of-type(2) .percent,
  // .skills-detail:nth-of-type(2) .percentage {
  //   color: $pink;
  // }

  // .skills-detail:nth-of-type(3) .percent,
  // .skills-detail:nth-of-type(3) .percentage {
  //   color: $orange;
  // }

  // .skills-detail:nth-of-type(4) .percent,
  // .skills-detail:nth-of-type(4) .percentage {
  //   color: $yellow;
  // }

  // .skills-detail:nth-of-type(5) .percent,
  // .skills-detail:nth-of-type(5) .percentage {
  //   color: $green;
  // }

  // .skills-detail:nth-of-type(6) .percent,
  // .skills-detail:nth-of-type(6) .percentage {
  //   color: $blue;
  // }
}

.skills-detail {
  padding: 1rem 1rem 0 1rem;

  h4 {
    line-height: 1.2;
  }
}

.skills-chart1,
.skills-chart2,
.skills-chart3,
.skills-chart4,
.skills-chart5,
.skills-chart6 {
  fill: none;
  stroke-linecap: round;
  stroke-width: 90px;
}

.skills-chart1 {
  stroke-dasharray: 2200;
  stroke-dashoffset: -2;
}

.skills-chart2 {
  stroke-dasharray: 2250;
  stroke-dashoffset: -1;
}

.skills-chart3 {
  stroke-dasharray: 1600;
  stroke-dashoffset: -4;
}

.skills-chart4 {
  stroke-dasharray: 1550;
  stroke-dashoffset: -3.9;
}

.skills-chart5 {
  stroke-dasharray: 2200;
  stroke-dashoffset: -1.9;
}

.skills-chart6 {
  stroke-dasharray: 2160;
  stroke-dashoffset: -1.7;
}