// Icons
@font-face {
  font-display: block;
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('../icons/icomoon.eot?hfsdfw');
  src: url('../icons/icomoon.eot?hfsdfw#iefix') format('embedded-opentype'),
       url('../icons/icomoon.ttf?hfsdfw') format('truetype'),
       url('../icons/icomoon.woff?hfsdfw') format('woff'),
       url('../icons/icomoon.svg?hfsdfw#icomoon') format('svg');
}

[class^="icon-"],
[class*=" icon-"] {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'icomoon' !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

.icon-bubble:before {
  content: "\e905";
}

.icon-bulb:before {
  content: "\e903";
}

.icon-check-circle:before {
  content: "\f058";
}

.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}

.icon-cloud:before {
  content: "\e901";
}

.icon-code:before {
  content: "\ea80";
}

.icon-external-link:before {
  content: "\f14c";
}

.icon-file-photo-o:before {
  content: "\f1c5";
}

.icon-file-picture-o:before {
  content: "\f1c5";
}

.icon-graphic:before {
  content: "\f1c5";
}

.icon-html5:before {
  content: "\eae4";
}

.icon-javascript:before {
  content: "\e900";
}

.icon-lab:before {
  content: "\e908";
}

.icon-paint:before {
  content: "\e90c";
}

.icon-params:before {
  content: "\e907";
}

.icon-pen:before {
  content: "\e904";
}

.icon-stylus:before {
  content: "\e909";
}

.icon-phone:before {
  content: "\e906";
}

.icon-settings:before {
  content: "\e902";
}
