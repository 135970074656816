@font-face {
  font-display: swap;
  font-family: "gotham_black";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/gotham_black.woff2") format("woff2"),
    url("../fonts/gotham_black.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "gotham_light";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/gotham_light.woff2") format("woff2"),
    url("../fonts/gotham_light.woff") format("woff");
}

$font-primary: "gotham_light", sans-serif;
$font-secondary: "gotham_black", sans-serif;
