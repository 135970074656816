// Process
.process {
  padding-bottom: 3rem;
}

.process-detail {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 2rem auto auto;
  position: relative;
  
  &:last-of-type {
    margin-bottom: 1.5rem;
  }

  &:nth-of-type(1) .icon:before {
    background-color: $purple;
  }

  &:nth-of-type(2) .icon:before {
    background-color: $pink;
  }

  &:nth-of-type(3) .icon:before {
    background-color: $orange;
  }

  &:nth-of-type(4) .icon:before {
    background-color: $yellow;
  }

  &:nth-of-type(5) .icon:before {
    background-color: $green;
  }

  &:nth-of-type(6) .icon:before {
    background-color: $blue;
  }

  &:nth-of-type(7) .icon:before {
    background-color: hsl(207, 90%, 36%);
  }

  &:nth-of-type(8) .icon:before {
    background-color: hsl(250, 78%, 40%);
  }

  .process-icon {
    background: 0 0;
    color: $white;
    text-decoration: none;

    &:hover {
      text-decoration: none;

      .icon {
        transform: scale(.3) translateY(-158%);

        &:before {
          box-shadow: inset 0 .125rem .25rem rgba($black, .45),
                      inset 0 .25rem .5rem rgba($black, .22);
        }

        & > h3 {
          opacity: 0;
        }
      }

      .process-info {
        box-shadow: inset 0 .875rem 1.75rem rgba($black, .25),
                    inset 0 .625rem .625rem rgba($black, .22);
        opacity: 1;
      }

      i {
        top: 5.75rem;
      }
    }

    .icon {
      border-radius: 50%;
      height: 16rem;
      position: relative;
      transform: scale(1) translateY(0);
      transition: all .35s ease-in-out;
      width: 16rem;
      z-index: 10;

      & > h3 {
        color: $white;
        font-size: 1.5rem;
        left: 0;
        line-height: 1;
        margin: 1.25rem 2rem .5rem;
        opacity: 1;
        position: absolute;
        right: 0;
        text-shadow: 0 .125rem .25rem rgba($black, .45),
                     0 .25rem .5rem rgba($black, .22);
        text-transform: uppercase;
        top: 8.5rem;
      }

      &:before {
        border: .625rem solid rgba($white, .25);
        border-radius: 50%;
        box-shadow: inset 0 0.125rem 0.25rem rgba($black, .3),
                    inset 0 0.5rem 0.625rem rgba($black, .22);
        box-sizing: border-box;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all .35s ease-in-out;
        width: 100%;
      }
    }

    i {
      font-size: 5rem;
      left: 0;
      position: absolute;
      right: 0;
      text-shadow: 0 .125rem .25rem rgba($black, .45),
                   0 .25rem .5rem rgba($black, .22);
      top: 4rem;
    }

    .process-info {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      background: $grey6;
      border-radius: 50%;
      box-shadow: inset 0 .0625rem .0625rem rgba($black, .25),
                  inset 0 .0625rem .0625rem rgba($black, .22);
      height: 16rem;
      left: 50%;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all .35s ease-in-out;
      width: 16rem;

      h3 {
        color: $white;
        font-size: 1.375rem;
        line-height: 1;
        margin: 0 2rem;
        overflow-wrap: break-word;
        padding: 4rem 0 .5rem;
        position: relative;
        text-shadow: 0 0 .0625rem $white,
                     0 .0625rem .125rem rgba($black, .25);
        text-transform: uppercase;
      }

      p {
        border-top: .0625rem solid rgba($white, .5);
        color: $grey3;
        font-family: $font-primary;
        font-size: .875rem;
        line-height: 1.25;
        margin: 0 1rem;
        padding: .5rem;
      }
    }
  }
}
