// Breakpoints
@media (min-width: 36rem) {
  .avatar {
    max-height: calc(65vh - 7rem);
    max-width: none;
  }

  h3 {
    font-size: 3.75rem;
  }

  .info,
  .info.landscape {
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      font-size: 2.25rem;
    }
  }

  .story {
    max-width: 15rem;
    width: calc(25% - 0.625rem);
  }

  .process-detail,
  .skills-detail,
  .story-detail,
  .modal-half {
    flex: 0 0 50%;
  }

  .timeline {
    padding: 0 .9375rem;
  }

  .item_left,
  .item_right {
    position: relative;
  }

  .grid-item {
    margin: 1rem 1rem 0 0;
    width: calc(50% - .5rem);

    &:not(.hidden):nth-of-type(2n) { 
      margin: 1rem 0 0 0;
    }
  }

  .quote {
    max-width: calc(100vw - 1rem);
  }

  .modal-dialog {
    margin: 1.75rem auto;
    max-width: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - (2.5rem * 2));
  }

  .section-title h4 {
    height: 3.75rem;
  }
}

@media (min-width: 48rem) {
  .intro p {
    max-width: 32rem;
  }

  .info,
  .info.landscape {
    margin-bottom: 1.75rem;

    h1 {
      font-size: 2.75rem;
    }
    h2 {
      font-size: 2.5rem;
    }
  }

  .about-col {
    max-width: 32rem;
  }

  .ui-design {
    max-width: 28rem;
  }

  .process-detail,
  .skills-detail,
  .story-detail {
    flex: 0 0 25%;
  }

  .timeline {
    background: url("../images/timeline-bg.jpg") repeat-y scroll center center rgba($black, 0);
    width: 100%;

    .note {
      width: 47.5%;

      &:after {
        display: block;
      }

      &:nth-of-type(2) {
        margin-top: 4.5rem;
      }

      .arrow {
        display: block;
      }
    }
  }

  .grid-item {
    margin: 1rem 1rem 0 0;
    width: calc(33.333% - .6875rem);

    &:not(.hidden):first-of-type,
    &:not(.hidden):nth-of-type(3n + 1) {
      margin: 1rem 1rem 0 0;
    }

    &:not(.hidden):nth-of-type(2n) {
      margin: 1rem 1rem 0 0;
    }

    &:not(.hidden):nth-of-type(3n) {
      margin: 1rem 0 0 0;
    }
  }

  .modal-dialog {
    max-width: calc(100% - 1.875rem);
    min-height: calc(100% - 1.875rem);
    margin: .9375rem auto;
  }

  .modal-content {
    max-height: calc(100vh - 1.875rem);
  }

  .testimonials p {
    font-size: 1rem;
  }

  .quote {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 4.625rem);
    padding: 2rem 2.5rem;
    width: fit-content;
  }

  .meta {
    padding: 0 .9375rem 0 1.875rem;
    text-align: left;
  }

  .grid .row .col-sm-12:nth-of-type(2) {
    padding: 0 0 0 .9375rem;
  }
}

@media (min-width: 62rem) {

  .section-title h4 {
    font-size: 1.25rem;
    margin: 0.5rem auto 3rem;
  }

  .intro p,
  .about-intro p {
    font-size: 1.125rem;
  }

  .align-home {
    align-self: center;
  } 

  .avatar {
    margin-left: auto;
    max-height: calc(50vw - 7rem);
  }

  .home-container {
    flex-direction: row;
    padding: 0 3.5rem;
  }

  .about-container {
    flex-direction: row;
    justify-content: center;
    margin-top: 1.5rem;
    padding: 0 3rem;
  }

  .ui-design {
    margin: 0;
    max-width: 50%;
  }

  .intro {
    min-width: calc(50vw - 7rem);
    text-align: left;
  }

  .about-intro {
    margin: 0;
    max-width: 32rem;
    min-width: 50%;
    padding-left: 3rem;
    text-align: left;

    .section-title {
      margin-top: 0;
      padding: 0;
    }
  }

  .about-col {
    margin: 0;
    padding: 0;
  }

  .testimonials .author {
    margin: auto;
    max-width: 50rem;
    padding: 1.5rem 1.5rem 0;
  }

  .grid-item {
    width: calc(25% - .75rem);

    &:not(.hidden):nth-of-type(2n),
    &:not(.hidden):nth-of-type(3n),
    &:not(.hidden):nth-of-type(3n + 1) {
      margin: 1rem 1rem 0 0;
    }

    &:not(.hidden):nth-of-type(4n) {
      margin: 1rem 0 0 0;
    }

    &:not(.hidden):first-of-type,
    &:not(.hidden):nth-of-type(4n + 1) {
      margin: 1rem 1rem 0 0;
    }
  }

  .more-content .grid-item:not(.hidden) {
    width: calc(50% - .5rem);

    &:nth-of-type(4n + 1) {
      margin: 1rem 1rem 0 0;
    }

    &:nth-of-type(2n) {
      margin: 1rem 0 0 0;
    }
  }

  .modal-dialog {
    margin: 1rem auto;
  }

  .section-title h4 {
    height: auto;
    max-width: 58rem;
  }

  .meta {
    .blockquote,
    .intro {
      display: block;
    }
  }

  .quote {
    max-width: 52rem;
  }
}

@media (min-width: 75rem) {
  .info,
  .info.landscape {
    h1 {
    font-size: 3.25rem;
    }
    h2 {
    font-size: 3rem;
    }
  }

  .grid-item {
    width: calc(20% - .8125rem);

    &:not(.hidden):nth-of-type(2n),
    &:not(.hidden):nth-of-type(3n),
    &:not(.hidden):nth-of-type(3n + 1),
    &:not(.hidden):nth-of-type(4n + 1) {
      margin: 1rem 1rem 0 0;
    }

    &:not(.hidden):nth-of-type(5n) {
      margin: 1rem 0 0 0;
    }

    &:not(.hidden):first-of-type,
    &:not(.hidden):nth-of-type(5n + 1) {
      margin: 1rem 1rem 0 0;
    }
  }

  .timeline .note p {
    font-size: 1rem;
  }

  .testimonials-col {
    max-width: 75rem;
  }

  .quote {
    column-count: 2;
    column-gap: 3rem;
    max-width: 70rem;
    orphans: 3;
    text-align: justify;
    widows: 3;
  }

  .about-col {
    text-align: left;
  }
}

@media (min-width: 87.5rem) {
  .info,
  .info.landscape {
    margin-bottom: 2rem;

    h1 {
    font-size: 4rem;
    }
    h2 {
    font-size: 3rem;
    }
  }

  .grid-item {
    width: calc(16.6666% - .875rem);

    &:not(.hidden):nth-of-type(2n),
    &:not(.hidden):nth-of-type(3n),
    &:not(.hidden):nth-of-type(3n + 1),
    &:not(.hidden):nth-of-type(4n + 1),
    &:not(.hidden):nth-of-type(5n),
    &:not(.hidden):nth-of-type(5n + 1) {
      margin: 1rem 1rem 0 0;
    }

    &:not(.hidden):nth-of-type(6n) {
      margin: 1rem 0 0 0;
    }

    &:not(.hidden):first-of-type,
    &:not(.hidden):nth-of-type(6n + 1) {
      margin: 1rem 1rem 0 0;
    }
  }
}

@media (min-width: 118.75rem) {
  .grid-item {
    width: calc(12.5% - .875rem);

    &:not(.hidden):nth-of-type(2n),
    &:not(.hidden):nth-of-type(3n),
    &:not(.hidden):nth-of-type(3n + 1),
    &:not(.hidden):nth-of-type(4n + 1),
    &:not(.hidden):nth-of-type(5n),
    &:not(.hidden):nth-of-type(5n + 1),
    &:not(.hidden):nth-of-type(6n),
    &:not(.hidden):nth-of-type(6n + 1) {
      margin: 1rem 1rem 0 0;
    }

    &:not(.hidden):first-of-type,
    &:not(.hidden):nth-of-type(8n + 1) {
      margin: 1rem 1rem 0 1rem;
    }
  }

  .testimonials-col {
    max-width: 118.75rem;
  }
}