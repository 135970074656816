// Colours
$black: #000;
$white: #fff;
$white1: #eee;
$grey: #404040;
$grey1: #cfcfcf;
$grey2: #d6d6d6;
$grey3: #bfbfbf;
$grey4: #8c8c8c;
$grey5: #737373;
$grey6: #292929;
$body: $grey;

$green: #AEE000;
$darkgreen: #688600;
$medgreen: #8bb300;
$lightgreen: #bee632;

$pink: #E30078;
$darkpink: #9e0054;
$medpink: #cc006c;
$lightpink: #e83293;

$orange: #F88526;
$blue: #0BB4C3;
$yellow: #FCC100;
$purple: #7A07A4;
$darkpurple: #460091;

$focus: #80bdff;

.text-green {
  color: $green;
}

.text-orange {
  color: $orange;
}

.text-blue {
  color: $blue;
}

.text-pink {
  color: $pink;
}

.text-yellow {
  color: $yellow;
}