// Portfolio
.portfolio-layout {
  padding: 3rem 0;
}

.filters .btn {
  margin: .25rem .3125rem 0 0;
}

.grid {
  flex-basis: 0;
  flex-grow: 1;
  margin: 1.6875rem 0 1.5rem;
}

.grid-item {
  float: left;
  margin: 1rem 0 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;

  * {
    transition: all .6s ease-in-out;
  }

  &:hover {
    img {
      transform: scale(2.5) rotate(-6deg);
    }

    .overlay {
      opacity: 1;
    }

    .grid-item-info {
      margin-top: 0;
    }
  }

  &.hidden {
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    visibility: hidden;
    width: 0 !important;
  }

  a {
    display: flex;
  }

  img {
    width: 100%;
  }

  h3 {
    color: $yellow;
    font-family: $font-secondary;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: -.0625rem;
    line-height: 1;
    margin: 1rem 0 .25rem;
    text-transform: uppercase;
  }

  .overlay {
    background: linear-gradient(to bottom, rgba($black,.5) 0%, rgba($black,.75) 100%);
    backdrop-filter: blur(.3125rem);
    height: 100%;
    left: 0;
    opacity: 0;
    padding: 1.5rem .625rem;
    position: absolute;
    text-align: center;
    top: 0;
    transform: opacity 3s;
    width: 100%;
  }

  .grid-item-info {
    align-items: center;
    color: $yellow;
    display: flex;
    flex-direction: column;
    font-size: 2.5rem;
    height: calc(100% - 3rem);
    justify-content: center;
    margin-top: 1rem;
    left: .625rem;
    position: absolute;
    right: .625rem;
    top: 1.5rem;
    transition: margin .6s ease-in-out;

    span {
      color: $white;
      display: block;
      font-size: .75rem;
      line-height: 1.25;
      margin: .25rem .5rem .5rem .5rem;
      text-transform: uppercase;
    }
  }

  svg:not(:root).svg-inline--fa {
    display: block;
    margin: 0 auto;
    max-width: .875em;
  }
}

.meta {
  h2 {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: .9375rem;
  }

  p {
    font-size: .875rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .blockquote,
  .intro {
    display: none;
  }

  .blockquote-footer {
    font-size: .875rem;
  }

  .btn {
    margin-bottom: 1.875rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    + .btn {
      display: block;
      margin-top: 0;
    }
  }
}

.mouse {
  background: $white;
  background-image: linear-gradient(to bottom, $white, $grey3);
  border-radius: .9375rem;
  box-shadow: 0 .1875rem .375rem rgba($black, .16), 0 .1875rem .375rem rgba($black, .23);
  margin: .5rem auto 1rem;
  width: 1.5625rem;
  z-index: 1;
}

.mouse-icon {
  border: .125rem solid rgba($white, .11);
  border-radius: .9375rem;
  cursor: pointer;
  height: 3rem;
  position: relative;
  text-align: center;
  width: 1.5625rem;
}

.mouse-wheel {
  animation: 1.6s ease infinite wheel-up-down;
  background-color: $pink;
  border-radius: 50%;
  display: block;
  height: .375rem;
  margin: .125rem auto 0;
  width: .1875rem;
}

@keyframes wheel-up-down {
  0% {
    margin-top: .125rem;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    margin-top: 1.25rem;
    opacity: 0;
  }
}
