// Contact
.contact-me {
  margin: auto;
  max-width: 31.25rem;
  text-align: left;
  
  .btn {
    margin-top: 0;
  }

  .contact-me .error {
    bottom: .75rem;
    color: $pink;
    font-weight: bold;
    left: 5.625rem;
    position: absolute;
  }

  .success {
    background: $white;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    
    p {
      font-size: 120%;
      left: 2.8125rem;
      line-height: 3rem;
      position: absolute;
      top: 7.5rem;
      width: 20.625rem;
      
      img {
        float: left;
        padding: 0 .625rem 7.5rem 0;
      }
    }
  }
}

.form-group {
  margin-bottom: 1rem;
  
  label {
    display: inline-block;
    margin-bottom: .5rem;
    
    &.error {
      color: $pink;
      display: none;
    }
  }
}

[role=button],
a,
area,
button,
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

.form-control {
  background-clip: padding-box;
  background-color: $white;
  border-radius: .25rem;
  border: .0625rem solid $grey1;
  color: $grey;
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  padding: .375rem .75rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width: 16.875rem;
  
  &:focus {
    background-color: $white;
    border-color: $focus;
    box-shadow: 0 0 0 .2rem rgba($focus, .25);
    color: $grey;
    outline: 0;
  }

  .form-control.error {
    animation: shake .5s linear;
    border-color: $pink;
    outline-color: $pink;
  }
}

.main {
  left: 0;
  position: fixed !important;
  top: 0;
}

@keyframes shake {  
  41%,
  8% {
    transform: translateX(-.625rem);
  }

  25%,
  58% {
    transform: translateX(.625rem);
  }

  75% {
    transform: translateX(-.3125rem);
  }

  92% {
    transform: translateX(.3125rem);
  }

  0%,
  100% {
    transform: translateX(0);
  }
   }

.form-control.error {
  animation: shake .5s linear;
  animation: shake .5s linear;
  border-color: $pink;
  outline-color: $pink;
}

.required::after {
  color: $pink;
  content: '*';
}

.required:hover::after {
  content: '* This field is required';
}