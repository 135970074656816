body {
  background: url("../images/bg.jpg") center top no-repeat fixed;
  background-color: transparent !important;
  color: $body;
  display: flex;
  flex-direction: column;
  font-family: $font-primary;
  line-height: 1.5;
  text-align: center;
}

::selection {
  background-color: rgba($black, .875);
  color: $yellow;
}

.btn,
.story-number,
h1,
h2,
h3,
h5,
h6,
strong {
  font-family: $font-secondary;
  line-height: 1.5;
}

h3 {
  color: $darkpurple;
  font-size: 2rem;

  .panel-wrapper & {
    color: $yellow;
  }
}

h4 {
  font-size: 1.25rem;

  .panel-wrapper & {
    color: $white1;
  }
}

.align {
  align-items: center;
  align-self: center;
  width: 100vw;
}

.typed-cursor {
  animation: typedjsBlink .7s infinite;
  opacity: 1;
}

@keyframes typedjsBlink {
  50% {
    opacity: 0;
  }
}

.panel-wrapper {
  background: rgba($black, .875);
  background-attachment: fixed;
  background-image: url('../images/bg2.png');
  background-size: cover;

  &.footer {
    flex-direction: column;
    overflow: visible;
    position: relative;
  }
}

.home {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.layout {
  align-items: center;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  overflow: hidden;
  padding: 3rem .5rem;
}

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-column {
  flex-direction: column;
}

.section-title {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  h3 {
    line-height: 1;
  }

  h4 {
    font-size: 1rem;
    height: 4.5rem;
    margin: .5rem auto 0;
    max-width: 33rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.section-fix {
  margin-bottom: 1.5rem;
}
