.modal {
  bottom: 0;
  display: none;
  left: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1050;

  &.fade {
    opacity: 0;
    transition: opacity .15s linear;

    .modal-dialog {
      transform: translate(0, -25%);
      transition: transform .3s ease-out;
    }

    &.show {
      background-color: rgba($black, .5);
      backdrop-filter: blur(.125rem);
      display: block;
      opacity: 1;

      .modal-dialog {
        transform: translate(0, 0);
      }
    }

    .modal-dialog {
      transition: transform .3s ease-out;
      transform: translate(0,-25%);
    }
  }
  
  .modal-open & {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal-dialog {
  margin: .5rem;
  pointer-events: none;
  position: relative;
  width: auto;
}

.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - (.5rem * 2));
}

.modal-content {
  background-clip: padding-box;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .5rem;
  box-shadow: .0625rem .0625rem .625rem .125rem rgba($black, .75), .0625rem .1875rem 1.75rem .625rem rgba($black, .5);
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}

.modal-body {
  flex: 1 1 auto;
  padding: 1rem;
  position: relative;

  .align {
    width: auto;
  }

  img {
    max-width: 100%;
  }
}

.modal-header {
  align-items: flex-start;
  background: linear-gradient(to bottom, rgba($black, .75) 0%, rgba($black, .9) 100%);
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  .close {
    appearance: none;
    background-color: transparent;
    border: 0;
    font-size: 2rem;
    margin: -1rem -1rem -1rem auto;
    padding: .625rem 1rem;
    transition: color .3s ease-in-out;
  }
}

.modal-title {
  color: $yellow;
  font-family: $font-secondary;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: -.0625rem;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
}

.modal-footer p {
  color: $black;
  font-size: .875rem;
  margin: 0;
  text-transform: uppercase;
}

.close {
  color: $white;
  opacity: 1;
  text-shadow: none;

  &:focus,
  &:hover {
    color: $pink;
    opacity: 1;
  }

  &:not([disabled]):not(.disabled) {
    cursor: pointer;
    outline: 0;
  }
}

.ps__rail-y {
  display: none;
}