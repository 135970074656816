// Testimonials
.testimonials-layout {
  padding-bottom: 2rem;
}

.img {
  border-radius: 50%;
  height: 10rem;
  margin: 0 auto 1rem;
  position: relative;
  width: 10rem;
  
  .img-circle {
    border-radius: 50%;
    width: 10rem;
  }

  &:before {
    border-radius: 50%;
    box-shadow: inset 0 0 0 .625rem rgba($white, .6), 0 .0625rem .125rem rgba($black, .3);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .35s ease-in-out;
    width: 100%;
  }
}

.testimonials-col {
  margin: 0 -.5rem;
  max-width: 100vw;
}

.testimonials p {
  font-size: .875rem;
}

.testimonials .author {
  font-family: $font-secondary;
  line-height: 1.8rem;
  margin: 1rem 1.5rem 0;
  text-align: center;
}

.testimonials .quote-left,
.testimonials .quote-right {
  opacity: .5;
  width: 1.5rem;
}

.testimonials .quote-left {
  margin: 0 1rem 0 0;
}

.testimonials .quote-right {
  float: right;
  margin: 0 0 0 1rem;
}

.testimonials-padding {
  padding-bottom: 2.5rem;
}

.quote {
  background: $white;
  border-radius: .75rem;
  box-shadow: 0 .1875rem .375rem rgba($black, .16), 0 .1875rem .375rem rgba($black, .23);
  margin: 2rem auto 2.5rem;
  max-width: calc(100vw - 1rem);
  padding: 2rem 1.5rem;
  transition: all .5s ease 0s;
  user-select: none;
  
  &:hover {
    box-shadow: 0 1.1875rem 1.375rem rgba($black, .30), 0 .9375rem .75rem rgba($black, .22);
  }
}