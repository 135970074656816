html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  box-sizing: border-box;
  vertical-align: baseline;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: flex;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

a {
  vertical-align: baseline;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button, input {
  overflow: visible;
}

input, select {
  vertical-align: middle;
}

:after, :before {
  box-sizing: border-box;
}

.text-green {
  color: #aee000;
}

.text-orange {
  color: #f88526;
}

.text-blue {
  color: #0bb4c3;
}

.text-pink {
  color: #e30078;
}

.text-yellow {
  color: #fcc100;
}

@font-face {
  font-display: swap;
  font-family: gotham_black;
  font-style: normal;
  font-weight: normal;
  src: url("gotham_black.dacd1f58.woff2") format("woff2"), url("gotham_black.6cf18d83.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: gotham_light;
  font-style: normal;
  font-weight: normal;
  src: url("gotham_light.b26677ff.woff2") format("woff2"), url("gotham_light.9fc4017e.woff") format("woff");
}

@font-face {
  font-display: block;
  font-family: icomoon;
  font-style: normal;
  font-weight: normal;
  src: url("icomoon.b20bd8cc.eot");
  src: url("icomoon.b20bd8cc.eot#iefix") format("embedded-opentype"), url("icomoon.fc684a8f.ttf") format("truetype"), url("icomoon.0943385e.woff") format("woff"), url("icomoon.db28bb17.svg#icomoon") format("svg");
}

[class^="icon-"], [class*=" icon-"] {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  speak: never;
  text-transform: none;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icomoon !important;
}

.icon-bubble:before {
  content: "";
}

.icon-bulb:before {
  content: "";
}

.icon-check-circle:before {
  content: "";
}

.icon-chevron-left:before {
  content: "";
}

.icon-chevron-right:before {
  content: "";
}

.icon-cloud:before {
  content: "";
}

.icon-code:before {
  content: "";
}

.icon-external-link:before {
  content: "";
}

.icon-file-photo-o:before, .icon-file-picture-o:before, .icon-graphic:before {
  content: "";
}

.icon-html5:before {
  content: "";
}

.icon-javascript:before {
  content: "";
}

.icon-lab:before {
  content: "";
}

.icon-paint:before {
  content: "";
}

.icon-params:before {
  content: "";
}

.icon-pen:before {
  content: "";
}

.icon-stylus:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-settings:before {
  content: "";
}

.btn {
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-shadow: 0 -.0625rem .0625rem rgba(0, 0, 0, .35);
  background-color: #cfcfcf;
  background-image: linear-gradient(#bfbfbf, #d6d6d6);
  background-position: 0 0;
  border: .0625rem solid #d6d6d6;
  border-color: #fff #d6d6d6 #8c8c8c;
  border-radius: .75rem;
  outline: 0;
  margin: 1.5rem auto 0;
  padding: .3125rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
  display: inline-block;
  position: relative;
  overflow: visible;
  box-shadow: 0 .0625rem .1875rem rgba(0, 0, 0, .12), 0 .0625rem .125rem rgba(207, 207, 207, .24);
}

.btn:hover, .btn:hover:focus {
  background-color: #d6d6d6;
  background-image: linear-gradient(#d6d6d6, #bfbfbf);
  border-color: #fff #d6d6d6 #8c8c8c;
  box-shadow: 0 .875rem 1.75rem rgba(0, 0, 0, .25), 0 .625rem .625rem rgba(207, 207, 207, .22);
}

.btn:focus, .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled):active:focus {
  background-color: #cfcfcf;
  background-image: linear-gradient(#bfbfbf, #d6d6d6);
  border-color: #fff #d6d6d6 #8c8c8c;
  box-shadow: 0 .0625rem .1875rem rgba(0, 0, 0, .12), 0 .0625rem .125rem rgba(207, 207, 207, .24);
}

.btn.btn-link {
  box-shadow: none;
  background: none;
  border: 0;
  margin: 0;
}

.btn.btn-link:hover, .btn.btn-link:hover:focus {
  text-decoration: underline;
}

.btn.btn-link:not([disabled]):not(.disabled):active, .btn.btn-link:not([disabled]):not(.disabled):hover:focus {
  box-shadow: none;
  text-shadow: none;
  background: none;
}

.btn-lg {
  padding: .6875rem 1.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-success {
  color: #fff;
  text-shadow: 0 -.0625rem .0625rem rgba(0, 0, 0, .35);
  background-color: #aee000;
  background-image: linear-gradient(#8bb300, #aee000);
  border-color: #bee632 #aee000 #688600;
  box-shadow: 0 .0625rem .1875rem rgba(0, 0, 0, .12), 0 .0625rem .125rem rgba(174, 224, 0, .24);
}

.btn-success:hover, .btn-success:hover:focus {
  background-color: #aee000;
  background-image: linear-gradient(#aee000, #8bb300);
  border-color: #bee632 #aee000 #688600;
  box-shadow: 0 .875rem 1.75rem rgba(0, 0, 0, .25), 0 .625rem .625rem rgba(174, 224, 0, .22);
}

.btn-success:focus, .btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled):active:focus {
  background-color: #aee000;
  background-image: linear-gradient(#8bb300, #aee000);
  border-color: #bee632 #aee000 #688600;
  box-shadow: 0 .0625rem .1875rem rgba(0, 0, 0, .12), 0 .0625rem .125rem rgba(174, 224, 0, .24);
}

.btn.disabled, .btn:disabled {
  box-shadow: none;
  cursor: unset;
  opacity: .65;
  border-color: #bee632 #aee000 #688600;
}

.btn.disabled:hover, .btn:disabled:hover {
  box-shadow: none;
  background-color: #aee000;
  background-image: linear-gradient(#8bb300, #aee000);
}

.btn-danger {
  color: #fff;
  text-shadow: 0 -.0625rem .0625rem rgba(0, 0, 0, .35);
  background-color: #e30078;
  background-image: linear-gradient(#cc006c, #e30078);
  border-color: #e83293 #e30078 #9e0054;
  box-shadow: 0 .0625rem .1875rem rgba(0, 0, 0, .12), 0 .0625rem .125rem rgba(227, 0, 120, .24);
}

.btn-danger:hover, .btn-danger:hover:focus {
  background-color: #e30078;
  background-image: linear-gradient(#e30078, #cc006c);
  border-color: #e83293 #e30078 #9e0054;
  box-shadow: 0 .875rem 1.75rem rgba(0, 0, 0, .25), 0 .625rem .625rem rgba(227, 0, 120, .22);
}

.btn-danger:focus, .btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled):active:focus {
  background-color: #e30078;
  background-image: linear-gradient(#cc006c, #e30078);
  border-color: #e83293 #e30078 #9e0054;
  box-shadow: 0 .0625rem .1875rem rgba(0, 0, 0, .12), 0 .0625rem .125rem rgba(227, 0, 120, .24);
}

body {
  color: #404040;
  text-align: center;
  background: url("bg.376521d4.jpeg") top no-repeat fixed;
  flex-direction: column;
  font-family: gotham_light, sans-serif;
  line-height: 1.5;
  display: flex;
  background-color: rgba(0, 0, 0, 0) !important;
}

::selection {
  color: #fcc100;
  background-color: rgba(0, 0, 0, .875);
}

.btn, .story-number, h1, h2, h3, h5, h6, strong {
  font-family: gotham_black, sans-serif;
  line-height: 1.5;
}

h3 {
  color: #460091;
  font-size: 2rem;
}

.panel-wrapper h3 {
  color: #fcc100;
}

h4 {
  font-size: 1.25rem;
}

.panel-wrapper h4 {
  color: #eee;
}

.align {
  align-self: center;
  align-items: center;
  width: 100vw;
}

.typed-cursor {
  opacity: 1;
  animation: .7s infinite typedjsBlink;
}

@keyframes typedjsBlink {
  50% {
    opacity: 0;
  }
}

.panel-wrapper {
  background: rgba(0, 0, 0, .875) url("bg2.7c5282b6.png") 0 0 / cover fixed;
}

.panel-wrapper.footer {
  flex-direction: column;
  position: relative;
  overflow: visible;
}

.home {
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.layout {
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 3rem .5rem;
  display: flex;
  overflow: hidden;
}

.flex-wrapper {
  flex-wrap: wrap;
  justify-content: space-around;
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.section-title {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.section-title h3 {
  line-height: 1;
}

.section-title h4 {
  max-width: 33rem;
  height: 4.5rem;
  margin: .5rem auto 0;
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.section-fix {
  margin-bottom: 1.5rem;
}

.modal {
  z-index: 1050;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.modal.fade {
  opacity: 0;
  transition: opacity .15s linear;
}

.modal.fade.show {
  -webkit-backdrop-filter: blur(.125rem);
  backdrop-filter: blur(.125rem);
  opacity: 1;
  background-color: rgba(0, 0, 0, .5);
  display: block;
}

.modal.fade.show .modal-dialog {
  transform: translate(0);
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -25%);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  pointer-events: none;
  width: auto;
  margin: .5rem;
  position: relative;
}

.modal-dialog-centered {
  align-items: center;
  min-height: calc(100% - 1rem);
  display: flex;
}

.modal-content {
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .5rem;
  outline: 0;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
  box-shadow: .0625rem .0625rem .625rem .125rem rgba(0, 0, 0, .75), .0625rem .1875rem 1.75rem .625rem rgba(0, 0, 0, .5);
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-body .align {
  width: auto;
}

.modal-body img {
  max-width: 100%;
}

.modal-header {
  background: linear-gradient(rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .9) 100%);
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  margin: -1rem -1rem -1rem auto;
  padding: .625rem 1rem;
  font-size: 2rem;
  transition: color .3s ease-in-out;
}

.modal-title {
  color: #fcc100;
  letter-spacing: -.0625rem;
  text-transform: uppercase;
  margin: 0;
  font-family: gotham_black, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
}

.modal-footer p {
  color: #000;
  text-transform: uppercase;
  margin: 0;
  font-size: .875rem;
}

.close {
  color: #fff;
  opacity: 1;
  text-shadow: none;
}

.close:focus, .close:hover {
  color: #e30078;
  opacity: 1;
}

.close:not([disabled]):not(.disabled) {
  cursor: pointer;
  outline: 0;
}

.ps__rail-y {
  display: none;
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls] {
  cursor: pointer;
  background: rgba(115, 115, 115, .3);
  border: 0;
  border-radius: 1.875rem;
  width: .75rem;
  height: .75rem;
  margin: 0 .3125rem;
  transition: all .2s ease-in-out;
}

.tns-outer [aria-controls]:hover, .tns-outer [aria-controls].tns-nav-active {
  background: #e30078;
  transform: scale(1.618);
}

.tns-outer [aria-controls="testimonialsCarousel"] {
  color: #fff;
  text-align: center;
  text-shadow: 0 -.0625rem .0625rem rgba(0, 0, 0, .35);
  z-index: 1;
  background-color: #aee000;
  background-image: linear-gradient(#8bb300, #aee000);
  background-position: 0 0;
  border: .0625rem solid #aee000;
  border-color: #bee632 #aee000 #688600;
  border-radius: 3.125rem;
  outline: 0;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  padding: .3125rem 1.5rem;
  font-size: 3rem;
  line-height: 1.5;
  text-decoration: none;
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
  display: flex;
  position: absolute;
  top: 3rem;
  overflow: visible;
  box-shadow: 0 .0625rem .1875rem rgba(0, 0, 0, .12), 0 .0625rem .125rem rgba(190, 214, 50, .24);
}

.tns-outer [aria-controls="testimonialsCarousel"]:first-of-type:hover, .tns-outer [aria-controls="testimonialsCarousel"]:last-of-type:hover {
  transform: inherit;
}

.tns-outer [aria-controls="testimonialsCarousel"]:first-of-type {
  left: calc(50% - 10rem);
}

.tns-outer [aria-controls="testimonialsCarousel"]:first-of-type i {
  padding-right: .25rem;
  font-size: 2rem;
}

.tns-outer [aria-controls="testimonialsCarousel"]:last-of-type {
  right: calc(50% - 10rem);
}

.tns-outer [aria-controls="testimonialsCarousel"]:last-of-type i {
  padding-left: .25rem;
  font-size: 2rem;
}

.tns-outer [aria-controls="testimonialsCarousel"]:hover, .tns-outer [aria-controls="testimonialsCarousel"]:hover:focus {
  background-color: #aee000;
  background-image: linear-gradient(#aee000, #8bb300);
  border-color: #bee632 #aee000 #688600;
  box-shadow: 0 .875rem 1.75rem rgba(0, 0, 0, .25), 0 .625rem .625rem rgba(190, 214, 50, .22);
}

.tns-outer [aria-controls="testimonialsCarousel"]:focus, .tns-outer [aria-controls="testimonialsCarousel"]:not([disabled]):not(.disabled):active, .tns-outer [aria-controls="testimonialsCarousel"]:not([disabled]):not(.disabled):active:focus {
  background-color: #aee000;
  background-image: linear-gradient(#8bb300, #aee000);
  border-color: #bee632 #aee000 #688600;
  box-shadow: 0 .0625rem .1875rem rgba(0, 0, 0, .12), 0 .0625rem .125rem rgba(190, 214, 50, .24);
}

.tns-outer [data-action] {
  display: none;
}

.tns-controls {
  position: relative;
}

.tns-nav {
  margin-top: 1rem;
}

.tns-slider {
  transition: all;
}

.tns-slider > .tns-item {
  box-sizing: border-box;
  object-fit: contain;
}

.tns-slider > .tns-item:hover {
  cursor: grab;
}

.tns-slider > .tns-item:active {
  cursor: grabbing;
}

.tns-slider.single > .tns-item:hover, .tns-slider.single > .tns-item:active {
  cursor: auto;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  vertical-align: top;
  white-space: normal;
  display: inline-block;
}

.tns-horizontal .tns-no-subpixel:after {
  clear: both;
  content: "";
  display: table;
}

.tns-horizontal .tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  min-height: .0625rem;
  position: relative;
  left: 0;
}

.tns-gallery > .tns-item {
  transition: transform, opacity;
  position: absolute;
  left: -100%;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  transition: all .25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  opacity: 0;
  max-height: calc(100vh - 17rem);
  transition: opacity .6s;
}

.tns-lazy-img.tns-slide-active {
  opacity: 1;
}

.tns-ah {
  transition: height;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000rem;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  filter: alpha(opacity= 100);
  opacity: 1;
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  filter: alpha(opacity= 0);
  opacity: 0;
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  width: 19.375rem;
  height: .625rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.33%;
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  clear: both;
  content: "";
  display: table;
}

.tns-t-ct > div {
  float: left;
  width: 1.42857%;
  height: 10px;
}

.particles {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.align-home {
  align-self: flex-start;
}

.home-container {
  flex-direction: column;
  align-items: center;
  padding: 3rem 1.4375rem 0;
  display: flex;
}

.intro {
  color: #fff;
}

.intro p:last-of-type {
  margin-bottom: 0;
}

.info {
  margin-bottom: 1.25rem;
}

.info h1 {
  color: #fcc100;
  margin: 0;
  font-size: 2rem;
  line-height: 1;
}

.info h2 {
  color: #fff;
  margin: 0 auto;
  font-family: gotham_light, sans-serif;
  font-size: 1.75rem;
  line-height: 1;
}

.home .align {
  z-index: 2;
}

.avatar {
  z-index: 2;
  max-width: calc(100vw - 2.875rem);
  max-height: calc(50vh - 6rem);
  margin-top: 1.4375rem;
}

.ui-design {
  max-width: 36rem;
  margin: 0 auto 1.5rem;
}

.about-container {
  flex-direction: column;
  align-items: center;
  padding: 0 1.4375rem;
  display: flex;
}

.about-intro {
  margin: auto;
}

.about-intro p:last-of-type {
  margin-bottom: 0;
}

.about-col {
  margin: auto;
}

.story {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: calc(50% - .625rem);
  margin-bottom: 1.5rem;
  display: flex;
}

.story-number {
  font-size: 2.5rem;
}

.story-description {
  font-family: gotham_black, sans-serif;
}

.more-content span {
  display: none;
}

.more-content .grid-item {
  justify-content: center;
  width: 100%;
  display: flex;
}

.more-content .grid-item:hover a {
  overflow: hidden;
}

span.note-isotopes {
  margin: 1.6875rem 0 0;
  display: block;
  overflow: hidden;
}

.more-link {
  clear: both;
  display: block;
}

.item_left {
  clear: left;
  float: left;
  position: relative;
}

.item_right {
  clear: right;
  float: right;
  position: relative;
}

.timeline {
  background: none;
  height: calc(100% - 1.5rem);
  margin: 0 auto;
  position: relative;
}

.timeline .title {
  clear: both;
  color: #404040;
  text-align: center;
  text-transform: uppercase;
  background: #fff;
  border: .1875rem solid #d6d6d6;
  border-radius: .375rem;
  width: 13.125rem;
  height: 4.0625rem;
  margin: 1.9375rem auto;
  padding: .9375rem 0;
  font-size: 1.125rem;
  font-weight: 400;
}

.timeline .note {
  z-index: 550;
  background: #fff;
  border-radius: .75rem;
  width: 100%;
  margin: 0 0 1.5rem;
  padding: 2rem 1.5rem;
  list-style-type: none;
  transition: all .5s;
  position: relative;
  box-shadow: 0 .1875rem .375rem rgba(0, 0, 0, .16), 0 .1875rem .375rem rgba(0, 0, 0, .23);
}

.timeline .note h4 {
  color: #737373;
  text-transform: uppercase;
  font-family: gotham_black, sans-serif;
  font-size: 1.5rem;
  line-height: 1;
}

.timeline .note h5 {
  font-size: 1rem;
  font-weight: 300;
}

.timeline .note p {
  margin: .5rem 0;
  font-size: .875rem;
}

.timeline .note .date {
  text-transform: uppercase;
  font-size: .875rem;
}

.timeline .note .arrow {
  color: #fff;
  font-family: fontAwesome;
  font-size: .75rem;
  transition: all .5s;
  display: none;
  position: absolute;
  top: 2rem;
  left: auto;
  right: -1.6%;
  transform: rotate(360deg);
}

.timeline .note:after {
  content: "";
  z-index: 20;
  background: #737373;
  border: .125rem solid #404040;
  border-radius: 50%;
  width: 1.125rem;
  height: 1.125rem;
  display: none;
  position: absolute;
  top: 1.875rem;
  right: calc(-2.5vw - .5rem);
}

.timeline .note:nth-of-type(2n):after {
  left: calc(-2.5vw - .5rem);
  right: auto;
}

.timeline .note:nth-of-type(2n) .arrow {
  left: -1.6%;
  right: auto;
  transform: rotate(180deg);
}

.timeline .note:last-of-type {
  margin-bottom: 0;
}

.timeline .note:hover {
  background: #fff;
  box-shadow: 0 1.1875rem 2.375rem rgba(0, 0, 0, .3), 0 .9375rem .75rem rgba(0, 0, 0, .22);
}

.timeline .note:hover:after {
  background: #e83293;
  border: .125rem solid #e30078;
}

.timeline .note:hover .arrow {
  color: #fff;
}

.timeline .note:hover h4 {
  color: #e30078;
}

.timeline .title + .note {
  margin-top: -1.25rem;
}

.jc-center {
  justify-content: center;
}

.chart {
  text-align: center;
  background: url("gradient-border.b2ff310e.svg") 0 -.125rem no-repeat;
  border-radius: 50%;
  width: 11.875rem;
  height: 11.875rem;
  margin-bottom: 1rem;
  display: inline-block;
  position: relative;
  box-shadow: inset 0 .0625rem rgba(0, 0, 0, .5);
}

.chart:after {
  content: "";
  border-radius: 50%;
  width: 10.625rem;
  height: 10.625rem;
  position: absolute;
  top: .625rem;
  left: .625rem;
}

.chart:before {
  content: "";
  opacity: .125;
  z-index: 0;
  background-color: #fff;
  border-radius: 50%;
  width: 8.125rem;
  height: 8.125rem;
  display: block;
  position: absolute;
  top: 1.875rem;
  left: 1.875rem;
  box-shadow: 0 0 .75rem rgba(0, 0, 0, .75), inset 0 .0625rem .625rem rgba(0, 0, 0, .75);
}

.chart canvas {
  border-radius: 50%;
  position: relative;
  top: .625rem;
}

.percent {
  color: #fff;
  text-shadow: 0 .1875rem .375rem rgba(0, 0, 0, .32), 0 .1875rem .375rem rgba(0, 0, 0, .46);
  font-family: gotham_black, sans-serif;
  font-size: 4rem;
  position: absolute;
  top: 25%;
  left: -1.25rem;
  right: 0;
}

.percentage {
  color: #fff;
  text-shadow: 0 .1875rem .375rem rgba(0, 0, 0, .32), 0 .1875rem .375rem rgba(0, 0, 0, .46);
  font-size: 2rem;
  position: absolute;
  top: 30%;
  right: 2.5rem;
}

.skills {
  padding-bottom: 3rem;
}

.skills h4 {
  color: #fff;
  margin-bottom: 2rem;
  font-family: gotham_black, sans-serif;
}

.skills h4 span {
  color: #eee;
  font-family: gotham_light, sans-serif;
}

.skills-detail {
  padding: 1rem 1rem 0;
}

.skills-detail h4 {
  line-height: 1.2;
}

.skills-chart1, .skills-chart2, .skills-chart3, .skills-chart4, .skills-chart5, .skills-chart6 {
  fill: none;
  stroke-linecap: round;
  stroke-width: 90px;
}

.skills-chart1 {
  stroke-dasharray: 2200;
  stroke-dashoffset: -2px;
}

.skills-chart2 {
  stroke-dasharray: 2250;
  stroke-dashoffset: -1px;
}

.skills-chart3 {
  stroke-dasharray: 1600;
  stroke-dashoffset: -4px;
}

.skills-chart4 {
  stroke-dasharray: 1550;
  stroke-dashoffset: -3.9px;
}

.skills-chart5 {
  stroke-dasharray: 2200;
  stroke-dashoffset: -1.9px;
}

.skills-chart6 {
  stroke-dasharray: 2160;
  stroke-dashoffset: -1.7px;
}

.portfolio-layout {
  padding: 3rem 0;
}

.filters .btn {
  margin: .25rem .3125rem 0 0;
}

.grid {
  flex-grow: 1;
  flex-basis: 0;
  margin: 1.6875rem 0 1.5rem;
}

.grid-item {
  float: left;
  z-index: 2;
  width: 100%;
  margin: 1rem 0 0;
  position: relative;
  overflow: hidden;
}

.grid-item * {
  transition: all .6s ease-in-out;
}

.grid-item:hover img {
  transform: scale(2.5)rotate(-6deg);
}

.grid-item:hover .overlay {
  opacity: 1;
}

.grid-item:hover .grid-item-info {
  margin-top: 0;
}

.grid-item.hidden {
  visibility: hidden;
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.grid-item a {
  display: flex;
}

.grid-item img {
  width: 100%;
}

.grid-item h3 {
  color: #fcc100;
  letter-spacing: -.0625rem;
  text-transform: uppercase;
  margin: 1rem 0 .25rem;
  font-family: gotham_black, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
}

.grid-item .overlay {
  -webkit-backdrop-filter: blur(.3125rem);
  backdrop-filter: blur(.3125rem);
  opacity: 0;
  text-align: center;
  transform: opacity 3s;
  background: linear-gradient(rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .75) 100%);
  width: 100%;
  height: 100%;
  padding: 1.5rem .625rem;
  position: absolute;
  top: 0;
  left: 0;
}

.grid-item .grid-item-info {
  color: #fcc100;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 3rem);
  margin-top: 1rem;
  font-size: 2.5rem;
  transition: margin .6s ease-in-out;
  display: flex;
  position: absolute;
  top: 1.5rem;
  left: .625rem;
  right: .625rem;
}

.grid-item .grid-item-info span {
  color: #fff;
  text-transform: uppercase;
  margin: .25rem .5rem .5rem;
  font-size: .75rem;
  line-height: 1.25;
  display: block;
}

.grid-item svg:not(:root).svg-inline--fa {
  max-width: .875em;
  margin: 0 auto;
  display: block;
}

.meta h2 {
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.meta h3 {
  font-size: .9375rem;
}

.meta p {
  font-size: .875rem;
}

.meta p:last-of-type {
  margin-bottom: 0;
}

.meta .blockquote, .meta .intro {
  display: none;
}

.meta .blockquote-footer {
  font-size: .875rem;
}

.meta .btn {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin-bottom: 1.875rem;
  overflow: hidden;
}

.meta .btn + .btn {
  margin-top: 0;
  display: block;
}

.mouse {
  z-index: 1;
  background: #fff linear-gradient(#fff, #bfbfbf);
  border-radius: .9375rem;
  width: 1.5625rem;
  margin: .5rem auto 1rem;
  box-shadow: 0 .1875rem .375rem rgba(0, 0, 0, .16), 0 .1875rem .375rem rgba(0, 0, 0, .23);
}

.mouse-icon {
  cursor: pointer;
  text-align: center;
  border: .125rem solid rgba(255, 255, 255, .11);
  border-radius: .9375rem;
  width: 1.5625rem;
  height: 3rem;
  position: relative;
}

.mouse-wheel {
  background-color: #e30078;
  border-radius: 50%;
  width: .1875rem;
  height: .375rem;
  margin: .125rem auto 0;
  animation: 1.6s infinite wheel-up-down;
  display: block;
}

@keyframes wheel-up-down {
  0% {
    opacity: 0;
    margin-top: .125rem;
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    margin-top: 1.25rem;
  }
}

.process {
  padding-bottom: 3rem;
}

.process-detail {
  border-radius: 50%;
  justify-content: center;
  margin: 2rem auto auto;
  display: flex;
  position: relative;
}

.process-detail:last-of-type {
  margin-bottom: 1.5rem;
}

.process-detail:first-of-type .icon:before {
  background-color: #7a07a4;
}

.process-detail:nth-of-type(2) .icon:before {
  background-color: #e30078;
}

.process-detail:nth-of-type(3) .icon:before {
  background-color: #f88526;
}

.process-detail:nth-of-type(4) .icon:before {
  background-color: #fcc100;
}

.process-detail:nth-of-type(5) .icon:before {
  background-color: #aee000;
}

.process-detail:nth-of-type(6) .icon:before {
  background-color: #0bb4c3;
}

.process-detail:nth-of-type(7) .icon:before {
  background-color: #0964ae;
}

.process-detail:nth-of-type(8) .icon:before {
  background-color: #3116b6;
}

.process-detail .process-icon {
  color: #fff;
  background: none;
  text-decoration: none;
}

.process-detail .process-icon:hover {
  text-decoration: none;
}

.process-detail .process-icon:hover .icon {
  transform: scale(.3)translateY(-158%);
}

.process-detail .process-icon:hover .icon:before {
  box-shadow: inset 0 .125rem .25rem rgba(0, 0, 0, .45), inset 0 .25rem .5rem rgba(0, 0, 0, .22);
}

.process-detail .process-icon:hover .icon > h3 {
  opacity: 0;
}

.process-detail .process-icon:hover .process-info {
  opacity: 1;
  box-shadow: inset 0 .875rem 1.75rem rgba(0, 0, 0, .25), inset 0 .625rem .625rem rgba(0, 0, 0, .22);
}

.process-detail .process-icon:hover i {
  top: 5.75rem;
}

.process-detail .process-icon .icon {
  z-index: 10;
  border-radius: 50%;
  width: 16rem;
  height: 16rem;
  transition: all .35s ease-in-out;
  position: relative;
  transform: scale(1)translateY(0);
}

.process-detail .process-icon .icon > h3 {
  color: #fff;
  opacity: 1;
  text-shadow: 0 .125rem .25rem rgba(0, 0, 0, .45), 0 .25rem .5rem rgba(0, 0, 0, .22);
  text-transform: uppercase;
  margin: 1.25rem 2rem .5rem;
  font-size: 1.5rem;
  line-height: 1;
  position: absolute;
  top: 8.5rem;
  left: 0;
  right: 0;
}

.process-detail .process-icon .icon:before {
  box-sizing: border-box;
  content: "";
  border: .625rem solid rgba(255, 255, 255, .25);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: all .35s ease-in-out;
  display: block;
  position: absolute;
  box-shadow: inset 0 .125rem .25rem rgba(0, 0, 0, .3), inset 0 .5rem .625rem rgba(0, 0, 0, .22);
}

.process-detail .process-icon i {
  text-shadow: 0 .125rem .25rem rgba(0, 0, 0, .45), 0 .25rem .5rem rgba(0, 0, 0, .22);
  font-size: 5rem;
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
}

.process-detail .process-icon .process-info {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  pointer-events: none;
  text-align: center;
  background: #292929;
  border-radius: 50%;
  width: 16rem;
  height: 16rem;
  transition: all .35s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: inset 0 .0625rem .0625rem rgba(0, 0, 0, .25), inset 0 .0625rem .0625rem rgba(0, 0, 0, .22);
}

.process-detail .process-icon .process-info h3 {
  color: #fff;
  overflow-wrap: break-word;
  text-shadow: 0 0 .0625rem #fff, 0 .0625rem .125rem rgba(0, 0, 0, .25);
  text-transform: uppercase;
  margin: 0 2rem;
  padding: 4rem 0 .5rem;
  font-size: 1.375rem;
  line-height: 1;
  position: relative;
}

.process-detail .process-icon .process-info p {
  color: #bfbfbf;
  border-top: .0625rem solid rgba(255, 255, 255, .5);
  margin: 0 1rem;
  padding: .5rem;
  font-family: gotham_light, sans-serif;
  font-size: .875rem;
  line-height: 1.25;
}

.testimonials-layout {
  padding-bottom: 2rem;
}

.img {
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  margin: 0 auto 1rem;
  position: relative;
}

.img .img-circle {
  border-radius: 50%;
  width: 10rem;
}

.img:before {
  content: "";
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: all .35s ease-in-out;
  display: block;
  position: absolute;
  box-shadow: inset 0 0 0 .625rem rgba(255, 255, 255, .6), 0 .0625rem .125rem rgba(0, 0, 0, .3);
}

.testimonials-col {
  max-width: 100vw;
  margin: 0 -.5rem;
}

.testimonials p {
  font-size: .875rem;
}

.testimonials .author {
  text-align: center;
  margin: 1rem 1.5rem 0;
  font-family: gotham_black, sans-serif;
  line-height: 1.8rem;
}

.testimonials .quote-left, .testimonials .quote-right {
  opacity: .5;
  width: 1.5rem;
}

.testimonials .quote-left {
  margin: 0 1rem 0 0;
}

.testimonials .quote-right {
  float: right;
  margin: 0 0 0 1rem;
}

.testimonials-padding {
  padding-bottom: 2.5rem;
}

.quote {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #fff;
  border-radius: .75rem;
  max-width: calc(100vw - 1rem);
  margin: 2rem auto 2.5rem;
  padding: 2rem 1.5rem;
  transition: all .5s;
  box-shadow: 0 .1875rem .375rem rgba(0, 0, 0, .16), 0 .1875rem .375rem rgba(0, 0, 0, .23);
}

.quote:hover {
  box-shadow: 0 1.1875rem 1.375rem rgba(0, 0, 0, .3), 0 .9375rem .75rem rgba(0, 0, 0, .22);
}

.contact-me {
  text-align: left;
  max-width: 31.25rem;
  margin: auto;
}

.contact-me .btn {
  margin-top: 0;
}

.contact-me .contact-me .error {
  color: #e30078;
  font-weight: bold;
  position: absolute;
  bottom: .75rem;
  left: 5.625rem;
}

.contact-me .success {
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-me .success p {
  width: 20.625rem;
  font-size: 120%;
  line-height: 3rem;
  position: absolute;
  top: 7.5rem;
  left: 2.8125rem;
}

.contact-me .success p img {
  float: left;
  padding: 0 .625rem 7.5rem 0;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  margin-bottom: .5rem;
  display: inline-block;
}

.form-group label.error {
  color: #e30078;
  display: none;
}

[role="button"], a, area, button, label, select, summary, textarea {
  touch-action: manipulation;
}

input:not([type="range"]) {
  touch-action: manipulation;
}

.form-control {
  color: #404040;
  background-color: #fff;
  background-clip: padding-box;
  border: .0625rem solid #cfcfcf;
  border-radius: .25rem;
  width: 16.875rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

.form-control:focus {
  color: #404040;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(128, 189, 255, .25);
}

.form-control .form-control.error {
  border-color: #e30078;
  outline-color: #e30078;
  animation: .5s linear shake;
}

.main {
  top: 0;
  left: 0;
  position: fixed !important;
}

@keyframes shake {
  41%, 8% {
    transform: translateX(-.625rem);
  }

  25%, 58% {
    transform: translateX(.625rem);
  }

  75% {
    transform: translateX(-.3125rem);
  }

  92% {
    transform: translateX(.3125rem);
  }

  0%, 100% {
    transform: translateX(0);
  }
}

.form-control.error {
  border-color: #e30078;
  outline-color: #e30078;
  animation: .5s linear shake;
}

.required:after {
  color: #e30078;
  content: "*";
}

.required:hover:after {
  content: "* This field is required";
}

.footer {
  min-height: auto;
  padding: 4.5rem 0;
}

.list-inline {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.list-inline-item {
  display: flex;
}

.list-inline-item:not(:last-child) {
  margin-right: .75rem;
}

.list-inline-item svg {
  vertical-align: middle;
  height: 2rem;
  margin: .875rem .5rem 1rem 0;
}

.fa-layers, .fa-github, .fa-linkedin, .fa-stack-overflow {
  vertical-align: middle;
  font-size: 2rem;
}

.fa-layers, .fa-github, .fa-linkedin {
  margin: 1rem .25rem 1rem 0;
}

.fa-stack-overflow {
  margin: 1rem .5rem 1rem 0;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer p {
  color: #737373;
  margin: 0;
}

.back-to-top {
  cursor: pointer;
  background-color: #fff;
  border: .125rem solid rgba(0, 0, 0, .875);
  border-radius: 50%;
  font-size: 3rem;
  line-height: 2rem;
  position: absolute;
  top: -1.6875rem;
  left: calc(50% - 1.5rem);
}

.back-to-top path {
  color: rgba(0, 0, 0, .875);
}

.back-to-top path, .back-to-top .rect {
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
}

.back-to-top:hover path {
  fill: #aee000;
}

.back-to-top:hover .rect {
  fill: rgba(0, 0, 0, .875);
}

@media (min-width: 36rem) {
  .avatar {
    max-width: none;
    max-height: calc(65vh - 7rem);
  }

  h3 {
    font-size: 3.75rem;
  }

  .info h1, .info.landscape h1 {
    font-size: 2.5rem;
  }

  .info h2, .info.landscape h2 {
    font-size: 2.25rem;
  }

  .story {
    width: calc(25% - .625rem);
    max-width: 15rem;
  }

  .process-detail, .skills-detail, .story-detail, .modal-half {
    flex: 0 0 50%;
  }

  .timeline {
    padding: 0 .9375rem;
  }

  .item_left, .item_right {
    position: relative;
  }

  .grid-item {
    width: calc(50% - .5rem);
    margin: 1rem 1rem 0 0;
  }

  .grid-item:not(.hidden):nth-of-type(2n) {
    margin: 1rem 0 0;
  }

  .quote {
    max-width: calc(100vw - 1rem);
  }

  .modal-dialog {
    max-width: calc(100% - 3.5rem);
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - 5rem);
  }

  .section-title h4 {
    height: 3.75rem;
  }
}

@media (min-width: 48rem) {
  .intro p {
    max-width: 32rem;
  }

  .info, .info.landscape {
    margin-bottom: 1.75rem;
  }

  .info h1, .info.landscape h1 {
    font-size: 2.75rem;
  }

  .info h2, .info.landscape h2 {
    font-size: 2.5rem;
  }

  .about-col {
    max-width: 32rem;
  }

  .ui-design {
    max-width: 28rem;
  }

  .process-detail, .skills-detail, .story-detail {
    flex: 0 0 25%;
  }

  .timeline {
    background: url("timeline-bg.9308aa9d.jpeg") center repeat-y;
    width: 100%;
  }

  .timeline .note {
    width: 47.5%;
  }

  .timeline .note:after {
    display: block;
  }

  .timeline .note:nth-of-type(2) {
    margin-top: 4.5rem;
  }

  .timeline .note .arrow {
    display: block;
  }

  .grid-item {
    width: calc(33.333% - .6875rem);
    margin: 1rem 1rem 0 0;
  }

  .grid-item:not(.hidden):first-of-type, .grid-item:not(.hidden):nth-of-type(3n+1), .grid-item:not(.hidden):nth-of-type(2n) {
    margin: 1rem 1rem 0 0;
  }

  .grid-item:not(.hidden):nth-of-type(3n) {
    margin: 1rem 0 0;
  }

  .modal-dialog {
    max-width: calc(100% - 1.875rem);
    min-height: calc(100% - 1.875rem);
    margin: .9375rem auto;
  }

  .modal-content {
    max-height: calc(100vh - 1.875rem);
  }

  .testimonials p {
    font-size: 1rem;
  }

  .quote {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: calc(100% - 4.625rem);
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 2.5rem;
  }

  .meta {
    text-align: left;
    padding: 0 .9375rem 0 1.875rem;
  }

  .grid .row .col-sm-12:nth-of-type(2) {
    padding: 0 0 0 .9375rem;
  }
}

@media (min-width: 62rem) {
  .section-title h4 {
    margin: .5rem auto 3rem;
    font-size: 1.25rem;
  }

  .intro p, .about-intro p {
    font-size: 1.125rem;
  }

  .align-home {
    align-self: center;
  }

  .avatar {
    max-height: calc(50vw - 7rem);
    margin-left: auto;
  }

  .home-container {
    flex-direction: row;
    padding: 0 3.5rem;
  }

  .about-container {
    flex-direction: row;
    justify-content: center;
    margin-top: 1.5rem;
    padding: 0 3rem;
  }

  .ui-design {
    max-width: 50%;
    margin: 0;
  }

  .intro {
    text-align: left;
    min-width: calc(50vw - 7rem);
  }

  .about-intro {
    text-align: left;
    min-width: 50%;
    max-width: 32rem;
    margin: 0;
    padding-left: 3rem;
  }

  .about-intro .section-title {
    margin-top: 0;
    padding: 0;
  }

  .about-col {
    margin: 0;
    padding: 0;
  }

  .testimonials .author {
    max-width: 50rem;
    margin: auto;
    padding: 1.5rem 1.5rem 0;
  }

  .grid-item {
    width: calc(25% - .75rem);
  }

  .grid-item:not(.hidden):nth-of-type(2n), .grid-item:not(.hidden):nth-of-type(3n), .grid-item:not(.hidden):nth-of-type(3n+1) {
    margin: 1rem 1rem 0 0;
  }

  .grid-item:not(.hidden):nth-of-type(4n) {
    margin: 1rem 0 0;
  }

  .grid-item:not(.hidden):first-of-type, .grid-item:not(.hidden):nth-of-type(4n+1) {
    margin: 1rem 1rem 0 0;
  }

  .more-content .grid-item:not(.hidden) {
    width: calc(50% - .5rem);
  }

  .more-content .grid-item:not(.hidden):nth-of-type(4n+1) {
    margin: 1rem 1rem 0 0;
  }

  .more-content .grid-item:not(.hidden):nth-of-type(2n) {
    margin: 1rem 0 0;
  }

  .modal-dialog {
    margin: 1rem auto;
  }

  .section-title h4 {
    max-width: 58rem;
    height: auto;
  }

  .meta .blockquote, .meta .intro {
    display: block;
  }

  .quote {
    max-width: 52rem;
  }
}

@media (min-width: 75rem) {
  .info h1, .info.landscape h1 {
    font-size: 3.25rem;
  }

  .info h2, .info.landscape h2 {
    font-size: 3rem;
  }

  .grid-item {
    width: calc(20% - .8125rem);
  }

  .grid-item:not(.hidden):nth-of-type(2n), .grid-item:not(.hidden):nth-of-type(3n), .grid-item:not(.hidden):nth-of-type(3n+1), .grid-item:not(.hidden):nth-of-type(4n+1) {
    margin: 1rem 1rem 0 0;
  }

  .grid-item:not(.hidden):nth-of-type(5n) {
    margin: 1rem 0 0;
  }

  .grid-item:not(.hidden):first-of-type, .grid-item:not(.hidden):nth-of-type(5n+1) {
    margin: 1rem 1rem 0 0;
  }

  .timeline .note p {
    font-size: 1rem;
  }

  .testimonials-col {
    max-width: 75rem;
  }

  .quote {
    column-count: 2;
    orphans: 3;
    text-align: justify;
    widows: 3;
    column-gap: 3rem;
    max-width: 70rem;
  }

  .about-col {
    text-align: left;
  }
}

@media (min-width: 87.5rem) {
  .info, .info.landscape {
    margin-bottom: 2rem;
  }

  .info h1, .info.landscape h1 {
    font-size: 4rem;
  }

  .info h2, .info.landscape h2 {
    font-size: 3rem;
  }

  .grid-item {
    width: calc(16.6666% - .875rem);
  }

  .grid-item:not(.hidden):nth-of-type(2n), .grid-item:not(.hidden):nth-of-type(3n), .grid-item:not(.hidden):nth-of-type(3n+1), .grid-item:not(.hidden):nth-of-type(4n+1), .grid-item:not(.hidden):nth-of-type(5n), .grid-item:not(.hidden):nth-of-type(5n+1) {
    margin: 1rem 1rem 0 0;
  }

  .grid-item:not(.hidden):nth-of-type(6n) {
    margin: 1rem 0 0;
  }

  .grid-item:not(.hidden):first-of-type, .grid-item:not(.hidden):nth-of-type(6n+1) {
    margin: 1rem 1rem 0 0;
  }
}

@media (min-width: 118.75rem) {
  .grid-item {
    width: calc(12.5% - .875rem);
  }

  .grid-item:not(.hidden):nth-of-type(2n), .grid-item:not(.hidden):nth-of-type(3n), .grid-item:not(.hidden):nth-of-type(3n+1), .grid-item:not(.hidden):nth-of-type(4n+1), .grid-item:not(.hidden):nth-of-type(5n), .grid-item:not(.hidden):nth-of-type(5n+1), .grid-item:not(.hidden):nth-of-type(6n), .grid-item:not(.hidden):nth-of-type(6n+1) {
    margin: 1rem 1rem 0 0;
  }

  .grid-item:not(.hidden):first-of-type, .grid-item:not(.hidden):nth-of-type(8n+1) {
    margin: 1rem 1rem 0;
  }

  .testimonials-col {
    max-width: 118.75rem;
  }
}

/*# sourceMappingURL=index.af70ce9f.css.map */
