// Story
.story {
  align-self: center;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.5rem;
  width: calc(50% - 0.625rem);
}

.story-number {
  font-size: 2.5rem;
}

.story-description {
  font-family: $font-secondary;
}
