// Buttons
.btn {
  -webkit-font-smoothing: antialiased;
  background-color: $grey1;
  background-image: linear-gradient(to bottom, $grey3, $grey2);
  background-position: 0 0;
  border-color: $white $grey2 $grey4;
  border-radius: .75rem;
  border-style: solid;
  border-width: .0625rem;
  box-shadow: 0 .0625rem .1875rem rgba($black, .12), 0 .0625rem .125rem rgba($grey1, .24);
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  line-height: 1.5;
  margin: 1.5rem auto 0;
  outline: 0;
  overflow: visible;
  padding: .3125rem 1.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: rgba($black, .35) 0 -.0625rem .0625rem;
  transition: all .3s cubic-bezier(.25, .8, .25, 1);

  &:hover,
  &:hover:focus {
    background-color: $grey2;
    background-image: linear-gradient(to bottom, $grey2, $grey3);
    border-color: $white $grey2 $grey4;
    box-shadow: 0 .875rem 1.75rem rgba($black, .25), 0 .625rem .625rem rgba($grey1, .22);
  }

  &:focus,
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled):active:focus {
    background-color: $grey1;
    background-image: linear-gradient(to bottom, $grey3, $grey2);
    border-color: $white $grey2 $grey4;
    box-shadow: 0 .0625rem .1875rem rgba($black, .12), 0 .0625rem .125rem rgba($grey1, .24);
  }

  &.btn-link {
    background: none;
    border: 0;
    box-shadow: none;
    margin: 0;

    &:hover,
    &:hover:focus {
      text-decoration: underline;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled):hover:focus {
      background: none;
      box-shadow: none;
      text-shadow: none;
    }
  }
}

.btn-lg {
  font-size: 1.25rem;
  line-height: 1.5;
  padding: .6875rem 1.125rem;
}

.btn-success {
  background-color: $green;
  background-image: linear-gradient(to bottom, $medgreen, $green);
  border-color: $lightgreen $green $darkgreen;
  box-shadow: 0 .0625rem .1875rem rgba($black, .12), 0 .0625rem .125rem rgba($green, .24);
  color: $white;
  text-shadow: rgba($black, .35) 0 -.0625rem .0625rem;

  &:hover,
  &:hover:focus {
    background-color: $green;
    background-image: linear-gradient(to bottom, $green, $medgreen);
    border-color: $lightgreen $green $darkgreen;
    box-shadow: 0 .875rem 1.75rem rgba($black, .25), 0 .625rem .625rem rgba($green, .22);
  }

  &:focus,
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled):active:focus {
    background-color: $green;
    background-image: linear-gradient(to bottom, $medgreen, $green);
    border-color: $lightgreen $green $darkgreen;
    box-shadow: 0 .0625rem .1875rem rgba($black, .12), 0 .0625rem .125rem rgba($green, .24);
  }
}

.btn.disabled,
.btn:disabled {
  border-color: $lightgreen $green $darkgreen;
  box-shadow: none;
  cursor: unset;
  opacity: .65;

  &:hover {
    background-color: $green;
    background-image: linear-gradient(to bottom, $medgreen, $green);
    box-shadow: none;
  }
}

.btn-danger {
  background-color: $pink;
  background-image: linear-gradient(to bottom, $medpink, $pink);
  border-color: $lightpink $pink $darkpink;
  box-shadow: 0 .0625rem .1875rem rgba($black, .12), 0 .0625rem .125rem rgba($pink, .24);
  color: $white;
  text-shadow: rgba($black, .35) 0 -.0625rem .0625rem;

  &:hover,
  &:hover:focus {
    background-color: $pink;
    background-image: linear-gradient(to bottom, $pink, $medpink);
    border-color: $lightpink $pink $darkpink;
    box-shadow: 0 .875rem 1.75rem rgba($black, .25), 0 .625rem .625rem rgba($pink, .22);
  }

  &:focus,
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled):active:focus {
    background-color: $pink;
    background-image: linear-gradient(to bottom, $medpink, $pink);
    border-color: $lightpink $pink $darkpink;
    box-shadow: 0 .0625rem .1875rem rgba($black, .12), 0 .0625rem .125rem rgba($pink, .24);
  }
}