// About
.ui-design {
  margin: 0 auto 1.5rem;
  max-width: 36rem;
}

.about-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 1.4375rem 0;
}

.about-intro {
  margin: auto;

  p:last-of-type {
    margin-bottom: 0;
  }
}

.about-col {
  margin: auto;
}