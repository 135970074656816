// Tiny Slider
.tns-outer {
  padding: 0 !important;

  [hidden] {
    display: none !important;
  }

  [aria-controls] {
    cursor: pointer;
    background: rgba($grey5, 0.3);
    border: 0;
    border-radius: 1.875rem;
    height: 0.75rem;
    margin: 0 0.3125rem;
    transition: 0.2s ease-in-out;
    width: 0.75rem;

    &:hover,
    &.tns-nav-active {
      background: $pink;
      transform: scale(1.618);
    }
  }

  [aria-controls="testimonialsCarousel"] {
    align-items: center;
    background-color: $green;
    background-image: linear-gradient(to bottom, $medgreen, $green);
    background-position: 0 0;
    border-color: $lightgreen $green $darkgreen;
    border-radius: 3.125rem;
    border-style: solid;
    border-width: 0.0625rem;
    box-shadow: 0 0.0625rem 0.1875rem rgba($black, 0.12),
      0 0.0625rem 0.125rem rgba(190, 214, 50, 0.24);
    color: $white;
    display: flex;
    font-size: 3rem;
    height: 4rem;
    justify-content: center;
    line-height: 1.5;
    outline: 0;
    overflow: visible;
    padding: 0.3125rem 1.5rem;
    position: absolute;
    text-align: center;
    text-decoration: none;
    text-shadow: rgba($black, 0.35) 0 -0.0625rem 0.0625rem;
    top: 3rem;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 4rem;
    z-index: 1;

    &:first-of-type,
    &:last-of-type {
      &:hover {
        transform: inherit;
      }
    }

    &:first-of-type {
      left: calc(50% - 10rem);

      i {
        font-size: 2rem;
        padding-right: 0.25rem;
      }
    }

    &:last-of-type {
      right: calc(50% - 10rem);

      i {
        font-size: 2rem;
        padding-left: 0.25rem;
      }
    }

    &:hover,
    &:hover:focus {
      background-color: $green;
      background-image: linear-gradient(to bottom, $green, $medgreen);
      border-color: $lightgreen $green $darkgreen;
      box-shadow: 0 0.875rem 1.75rem rgba($black, 0.25),
        0 0.625rem 0.625rem rgba(190, 214, 50, 0.22);
    }

    &:focus,
    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled):active:focus {
      background-color: $green;
      background-image: linear-gradient(to bottom, $medgreen, $green);
      border-color: $lightgreen $green $darkgreen;
      box-shadow: 0 0.0625rem 0.1875rem rgba($black, 0.12),
        0 0.0625rem 0.125rem rgba(190, 214, 50, 0.24);
    }
  }

  [data-action] {
    display: none;
  }
}

.tns-controls {
  position: relative;
}

.tns-nav {
  margin-top: 1rem;
}

.tns-slider {
  transition: all 0s;

  > .tns-item {
    box-sizing: border-box;
    object-fit: contain;

    &:hover {
      cursor: grab;
    }

    &:active {
      cursor: grabbing;
    }
  }
}

.tns-slider.single > .tns-item {
  &:hover {
    cursor: auto;
  }

  &:active {
    cursor: auto;
  }
}

.tns-horizontal {
  &.tns-subpixel {
    white-space: nowrap;

    > .tns-item {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
    }
  }

  .tns-no-subpixel {
    &:after {
      clear: both;
      content: "";
      display: table;
    }

    > .tns-item {
      float: left;
    }
  }

  &.tns-carousel.tns-no-subpixel > .tns-item {
    margin-right: -100%;
  }
}

.tns-no-calc {
  left: 0;
  position: relative;
}

.tns-gallery {
  left: 0;
  min-height: 0.0625rem;
  position: relative;

  > .tns-item {
    left: -100%;
    position: absolute;
    transition: transform 0s, opacity 0s;
  }

  > .tns-slide-active {
    left: auto !important;
    position: relative;
  }

  > .tns-moving {
    transition: all 0.25s;
  }
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  max-height: calc(100vh - 17rem);
  opacity: 0;
  transition: opacity 0.6s;

  &.tns-slide-active {
    opacity: 1;
  }
}

.tns-ah {
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  left: -10000rem;
  position: absolute;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  filter: alpha(opacity=100);
  opacity: 1;
  z-index: 0;
}

.tns-normal,
.tns-fadeOut {
  filter: alpha(opacity=0);
  opacity: 0;
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;

  > div,
  > li {
    display: inline-block;
  }
}

.tns-t-subp2 {
  height: 0.625rem;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 19.375rem;
}

.tns-t-ct {
  position: absolute;
  right: 0;
  width: calc(100% * 70 / 3);

  &:after {
    clear: both;
    content: "";
    display: table;
  }

  > div {
    float: left;
    height: 10px;
    width: calc(100% / 70);
  }
}
